.social_login_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social_login_content {
    display: flex;
    gap: 10px;
}

.loader_outer img{
    height: 100px;
    width: 100px;
}
.social_media_outer {
    height: 38px !important;
    width: 38px !important;
    border-radius:6px !important;
    text-align: center !important;
    box-shadow: unset !important;
    padding: 7px !important;
}
.FaFacebookF {
    background: #e1e7f3 !important;
    color: #4267b3 !important;
    box-shadow: unset !important;
}
.FaTwitter {
    color: #1da1f2 !important;
    background: #dbf0fd !important;
}
.FaGoogle {
    background: #f9e1df !important;
    color: #db4437 !important;
}
/* .loader_outer{
    height: calc(100vh - 60px);
} */
.spinner_loader_outer .spinner-border {
    height: 26px;
    width: 26px;
}
@media screen and (max-width:767px) {
    .loader_outer {
        height: 40px;
        width: 40px;
    }
}