.report_content_outer {
    box-shadow: 2px 3px 10px #e4dede;
    border-radius: 6px;
    padding: 24px;
    height: calc(100vh - 171.25px);
}
.zone_outer {
    display: flex;
    gap: 10px;
}
.zone_content_wrapper{
    padding: 24px;
    width: 332px;
    border-radius: 6px;
}
.image_container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll::-webkit-scrollbar {
    display: none;
  }

.adjust_text {
    padding: 20px 20px 0px;
}
.zone_inner_content {
    display: flex;
    justify-content: space-between;
}
button.button_send {
    width: 103px;
    padding: 9px;
    background: #7367F0;
    border-radius: 60px;
    border: none;
    color: white;
}
.report_zone_heading{
    font-size: 15px;
    font-weight: 700;
    font-style: italic;
    line-height: 21px;
    color: #4B465C;
    margin-bottom: 0px !important;
}
.plan_note_outer {
    border: 1px solid #7367F0;
    padding: 24px;
    width: 332px;
}
.ground_floor_outer {
    justify-content: space-between;
    border-top: 1px solid #DBDADE;
    padding-top: 8px;
}
.legend_outer{
    border-radius: 6px;
    height: calc(100%);
    position: relative;
    padding-bottom: 60px;

}
/* .legend_list  li{
    padding: 20px 20px 0px;
} */
.legend_list .zone_content_wrapper{
    width: 100%;
}
/* .legend_list li:first-child{
    padding-bottom: 20px;
}
.legend_list li:last-child{
    padding-bottom: 20px;
} */
.report_outer{
    overflow: hidden;
   
}
.container{
    background-color: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    width: 900px;
    height: calc(100vh - 24px);
    margin: auto;
    padding: 10px;
}

.image-container img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.image_ai {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.ai, .user{
    display: flex;
    gap: 10px;
    align-items: flex-end;
    margin-bottom: 10px;
    justify-content: end;

}
.message_input{
    border:unset;
    box-shadow:unset;
}
.message_outer{
    position: absolute;
    bottom: 0px;
    width: 99%;
    display: flex;
    align-items: center;
    border: 1px solid #7367F0;
    box-shadow: 5px 5px 10px #dad9e2;
    border-radius: 68px;
    background-color: #fff;
}
.user{
justify-content: flex-end;
}

.message-container{
    border: 0.5px solid;
                border-radius: 10px;
                border-bottom-left-radius: 0;
                padding: 10px;
                max-width: 80%;
                background: linear-gradient(to right, #4f00ff, #00dbde);
                color: white;
    
}

.user .message-container{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 4px;
    /* background: linear-gradient(to right, #c02425, #f0cb35) */
}
.legend_list li:nth-child(2) {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 20px;
}
.legend_list li p{
    color : #4B465C;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0px !important;
    font-family: "Public Sans", sans-serif;
}
.confirm_submit_btn {
    padding: 10px 20px;
    font-size: 15px;
    font-family: "Public Sans", sans-serif;
    font-weight: 500;
}
.report_response_outer{
    padding-right: 15px;
    overflow: auto;
    height: calc(100%);    
}


.ai.user_ai {
    justify-content: flex-start;
}
.container{
    background-color: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    margin: auto;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.report_diagram{
    height: 440px;
    width: 440px;
    object-fit: contain;
}
.loading_container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.report_outer .dashboard_plan_heading {
    padding-bottom: 0px;
}
.plan_name_heading{
    font-size: 24px;
    padding-left: 20px;
    margin-top: 10px;
}
.imageloading_container{
height: calc(100vh - 11rem);
}
@media screen and (max-width:991px){
    .report_content_outer {
        margin-top: 25px;
    }
    .report_diagram{
        width: 100%;
        height: 100%;
    }
    .zone_content_wrapper {
        width: 100%;
    }
    .zone_outer {
        flex-wrap: wrap;
    }
    .confirm_submit_btn{
    padding: 6px 15px;
    font-size: 14px;
    }
    .report_content_outer{
        padding: 20px;
    }
    /* .legend_outer{
        padding: 20px;
    } */
}

 @media screen and (max-width:767px){
    .zone_content_wrapper {
        padding: 18px;
    }
    .report_image_outer{
        margin-top: 20px ;
    }
    .badrequest_img{
    object-fit: contain;
    height: 227px;
    width: 100%;
}
.report_diagram{
    width: 100%;
  } 
.report_content_outer {
padding: 16px;
}
.report_zone_heading {
font-size: 13px;
}
}