.reviewModal_outer button.btn-close {
    display: none !important;
}
.review_modal_header{
    padding: 20px 20px 0px;
}
.cross_icon   svg{
    font-size: 30px;
    cursor: pointer;
}
.confirm_delete_text{
        font-size: 20px !important;
}
.deleteplan_outer .modal-footer {
    border-top: unset !important;
}
p.image_drop_box {
    font-size: 12px;
}
.cancel_button{
    background: white;
    border: 1px solid #7367F0 !important;
    color: #7367F0 !important;
}
.cancel_button:hover{
    background-color: white !important;
}
.modal-footer {
    border-top: unset;
}
.chanage_pass_btn_outer button {
    padding: 6px 15px;
    min-width: 98px;
}
.chanage_pass_btn_outer .cancel_button{
    border-radius: 6px;
}
 @media screen and (max-width:767px) {
    .confirm_delete_text {
        font-size: 16px !important;
    }
    
    
 }