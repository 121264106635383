@media screen and (min-width:1900px){
    .NotFound_img{
        height: 700px;
        width: 100%;
    }
}
@media screen and (min-width:1440px) {
    .not_found_wrapper{
        height: 100vh;
        align-items: center;
    }
}
 @media screen and (max-width:767px) {
    
     .NotFound_img{
     width: 100%;
     object-fit: contain;
     height: 249px;
     }
     .not_found_wrapper {
        height: 100vh;
        align-items: center;
    }
 }