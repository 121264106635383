
.select_plan_outer {
    display: flex;
    padding: 20px;
    justify-content: space-between;
}
.submit_plan_btn{
    width: 139px;
    height: 38px;
}
.up_down_arrow_outer svg {
    fill: rgb(75 70 92 / 50%);
}
.select_btn{
    width: 72px;
    height: 38px;
    border: 1px solid #ddd;
    border-radius: 6px;
    color:#4B465C
}
.alert.alert-warning.alert_quality {
    padding: 5px 10px;
    margin-right: 30px;
}
.dashboardmenu_container .dashboard_plan_heading {
    border-top: unset;
}
.cmn_status_text{
    padding: 5px 10px;
    border-radius: 4px;
      font-size: 13px;
    font-weight: 600;
    line-height: 14px;
}

.table_plan td{ 
 padding: 20px !important;
    
}
.table_plan  h6{
    margin-bottom: 0px !important;
    line-height: 30px;
    font-size: 15px;
    color: #6f6b7d;
}
.table_plan tr td:nth-child(2){ 
    
    color: #A8AAAE;
    font-weight: 400;
    font-size: 14px;
  }
  .table_plan tr td:first-child{ 
    color:  #6f6b7d;
    font-weight: 600;
    font-size: 16px;
  }
  .table_plan thead tr th{ 
    color:  #4B465C;
    font-weight: 600;
    font-size: 16px;
    padding: 5px 20px;
    vertical-align: middle;
  }
  .Search_plans_input::placeholder{
    color: #A8AAAE;
  }
.dashboardmenu_container{
    height: 100%;
    min-height: calc(100vh - 80px); 
}
.dashboard_container{
    min-height: calc(100vh - 80px);
    height: 100%;
}
.actions_wrapper svg{
    color:  #4B465C;
}

.table_plan thead tr{
    border-top: 1px solid #ddd;
}
/* dashboard menu-------------------------------------------------- */
.submit_plan_heading{
    color: #B7B5BE;
}
.drop_file_input{
    display: none;
}
.trash-icon{
    cursor: pointer;
}
.drop_file_wrapper{
    width: 100%;
    border: 1px dashed #ddd;
    padding: 40px;
    margin-top: 16px;
    border-radius: 10px;
    cursor: pointer;
}
.welcomne_text{
    font-size: 28px;
    color: #5d586c;
}

.file_upload {
    width: 50px;
    height: 50px;
    background: #f1f0f2;
    border-radius: 6px;
    text-align: center;
    padding-top: 12px;
}
.file_upload svg{
    font-size: 20px;
}
h3.drop_file_text{
    font-weight: 600;
}
.no_plan_img_outer {
    height: calc(100vh - 262px);
    align-items: center;
}

button.review_btn {
    color: #7367F0;
    border: 1px solid #7367F0;
    border-radius: 6px;
    padding: 5px 20px;
    background: white;
}
.submit-Plan_btn{
padding:7px 20px;
}
.review_btn_outer {
    padding:20px;
}
.Plan_Details_heading {
    color: #4B465C;
    font-size: 18px;
    font-weight: 600;
}
.no_plan_img{
    height: 400px;
    width: 400px;
}
td.plan_doesnotexist_heading {
    font-size: 20px !important;
}
.up_down_arrow_outer{
    display: flex;
    flex-direction: column;
}
@media screen and (min-width:1900px) {
    .no_plan_img {
        height: 520px;
        width: 520px;
    }
}
@media screen and (min-width:1536px){
   
    /* .Plan_Details_heading {
        font-size: 22px;
    }
    td.plan_doesnotexist_heading {
        font-size: 22px !important;
    } */
  
  
    /* .table_plan thead tr th {
        padding: 10px 40px
    }
    .table_plan td{
        padding: 20px 40px !important;
    }
    .select_plan_outer {
        padding: 25px 40px;
    } */
    /* .table_plan h6 {
        font-size: 22px;
    }
    .table_plan tr td:first-child {
        font-size: 20px ;
      }
      .table_plan tr td:nth-child(2){
        font-size: 20px ;
      }
      .cmn_status_text {
      font-size: 20px;
    }
    .Search_plans_input{
      font-size: 20px;
    } */
    /* .submit_plan_btn {
      width: 200px;
      height: 44px;
      font-size: 20px;
    } */
}


@media screen and (max-width:767px){
    .search_plan_wrapper.d-flex.gap-3 {
        margin-top: 20px;
    }
    .select_plan_outer{
        flex-wrap: wrap;
    }
    .addplan_header_outer {
        display: block !important;
    }
    .alert.alert-warning.alert_quality{
        margin: 10px !important;
    }
    label#drop_file{
        padding: 20px;
    }
    td.plan_doesnotexist_heading {
        font-size: 15px !important;
    }
    .table_plan tr td:first-child {
        font-size: 14px
    }
    .table_plan h6{
        font-size: 14px;
        line-height:23px
    }
    .submit_plan_btn {
        width: 134px;
        font-size: 14px;
    }
    .submit-Plan_btn {
        font-size: 14px;
    }
    button.review_btn {
        font-size: 14px;
    }
    .Plan_Details_heading {
        font-size: 16px;
    }
    .plan_tabular_data{
        scrollbar-width: thin;
    }
    img.no_plan_img {
        width: 100%;
    }
    .drop_file_text.mt-3 {
        font-size: 16px !important;
    }
    .welcomne_text{
    font-size: 16px;
    padding: 4px 20px;
    }
    .no_plan_img_outer {
        display: block;
    }
}