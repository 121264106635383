.navbar-nav{
    margin-left: auto;
    align-items: center;
    gap: 16px;
}
.flag_outer {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    overflow: hidden;
}
.flag_outer img{
    height: 30px;
    width: 30px;
}
.notification_outer {
    position: relative;
}
.notification_outer svg{
    font-size: 30px;
}
.notification_text {
    position: absolute;
    top: -1px;
    left: 14px;
    height: 18px;
    width: 18px;
    background: red;
    border-radius: 20px;
    text-align: center;
    color: white;
    font-size: 13px;
}
.profile_dropdown_wrapper #dropdown-basic:focus-visible {
    outline: unset !important;
    box-shadow: unset !important;
}
.navbar-toggler:focus{
    box-shadow: unset !important;
}
nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light {
    background: white !important;
    padding: 0px;
}
.navbar-toggler-icon{
    height: 22px;
    width: 22px;
}
.logout_btn {
    padding: 7px 17px;
    font-size: 15px;
}
.profile_dropdown_outer{
    background: transparent !important;
    border: none !important;
    padding: 0px;

}

.user_img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 60px;
}

.profile_item_wrapper{
    cursor: pointer;
    padding: 5px 15px !important;
    width: 198px;
}
ul.profile_item_wrapper li {
    border-bottom: 1px solid #ddd;
    padding: 10px 0px;
    font-size: 16px;
}
ul.profile_item_wrapper li svg{
    font-size: 19px;
}
ul.profile_item_wrapper li:hover{
    color:#7367F0 !important
}
ul.profile_item_wrapper li:last-child {
    border-bottom: unset;
}
.header_outer_wrapper{
    cursor: pointer;
}
.logout_text{
    font-size: 18px ;
    margin-bottom: 0px;
}
.profile_dropdown_wrapper .dropdown-menu.show {
    padding: 0px;
}
.quanti_logo{
    height: 50px;
    width: 50px;
    border-radius: unset;

}
.header_outer {
    padding: 10px 20px;
}

 
 @media screen and (min-width:992px){
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        left: auto !important;
        right: 1rem !important;
    }
 }
@media screen and (max-width:991px){
    .navbar-nav {
    align-items: start !important;
    margin: 15px 0px;
    }
    .navbar_outer div#basic-navbar-nav {
        margin-left: 10px;
    }
    
    
}
 @media screen and (max-width:767px){
    .logout_text {
        font-size: 15px;
    }
    .profile_item_wrapper{
        width: 185px;
    }
    ul.profile_item_wrapper li {
        font-size: 14px;
    }
 }