.public-sans{
  font-family: "Public Sans", sans-serif;
  font-style: normal;
}
h1,h3,h2,h4,h5,h6,label{
  font-family:"Public Sans", sans-serif ;
}

.cmn_btn{
  border: none !important;
  background: #7367F0 !important;
  color: white !important;
  border-radius: 6px !important;
}
:focus:not(:focus-visible){
 outline: none 
} 
.back_btn{
  padding: 20px;
  padding: 8px 35px;

}

::-webkit-scrollbar {
  width: 6px;
}
.image_preview{
  border-radius: 94px;
  object-fit: cover;
  height: 126px;
  width: 126px;
  border: 1px solid #7367F0;
}
h3.user_name {
  font-size: 17px;
  text-align: center;
  margin-top: 11px;
  color: #4B465C;
}

::-webkit-scrollbar-track {
  background: rgb(247, 244, 244); 
}
 

::-webkit-scrollbar-thumb {
  background: rgb(214, 207, 207); 
}


::-webkit-scrollbar-thumb:hover {
  background: #f8f6f6; 
}

.w-screen.aspect-video.-mt-40.pt-\[25\%\].px-24.absolute.text-white.bg-gradient-to-r.from-black {}

.cmn_heading_style{
  color:  #5d586c !important;
  font-size: 22px !important;
  font-weight: 700 !important;
}
.white_bg{
  background-color: white;
}
.cmn_container {
  background: #f8f7fa;
}
.error{
  color: red;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.cmn_padding{
  padding: 20px;
}
ul{
  padding-left: 0px !important;
  margin-bottom: 0px !important;
}
.active_btn{
  color: #28C76F;
  background-color: #ddf6e8;
}
.pending_btn{
  color: #FF9F43;
  background-color: #fef0e1;
}
.inactive_btn{
  background-color: #f1f1f2;
  color: #A8AAAE;
}
ul{
  list-style-type: none;
}
input{
  accent-color: #7367F0 ;
}
.cmn_small_heading{
  color: #4B465C !important;
  font-size: 15px ;
  font-weight: 400 !important;
}
.large_font{
  color: #4B465C;
  font-size: 32px;
  font-weight: 600;
}
.italic_font{
  font-weight: 400;
  font-size: 15px;
  font-style: italic;
  color: #4B465C;

}
.cmn_box-shadow {
  box-shadow: 2px 2px 10px #e4e3e3;
}
.dashboard_plan_heading{
  padding: 20px;
  font-weight: 600 !important;
  border-top: 1px solid #DBDADE;
}
.form-control:focus{
  box-shadow: unset !important;
}
select:focus-visible {
  outline:none !important;
}
input:focus-visible {
  outline:none !important;
}
.form-control:focus{
  border-color: #7064e8 !important; 
}
.cursor-pointer{
  cursor: pointer;
}
.ai_logo{
  height: 50px;
  width: 50px;


}
.semi_bold{
  font-weight: 600 !important;
}

@media screen and (min-width:1536px){

 

  /* .cmn_small_heading{
    font-size: 18px;
  }
  */
 

}
 @media screen and (min-width:1440px) {
  .ai_logo {
    height: 56px;
    width: 56px;
}
 }
@media screen and (max-width:1439px){
 
  .large_font{
    font-size: 26px;
  }
}
 @media screen and (max-width:1280px){
  .cmn_heading_style {
    font-size: 17px !important;
}
 }

 
@media screen and (max-width:991px){
 
  .dashboard_plan_heading {
    padding: 17px
}
.ai_logo {
  height: 40px;
  width: 40px;
}
}

@media screen and (max-width:767px){
  .cmn_small_heading {
    font-size: 13px;
  }
  .back_btn{
    padding: 4px 23px;
    font-size: 14px;
  }
  .cmn_heading_style {
    font-size: 15px !important;
}
}