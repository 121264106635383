.pagination_wrapper ul{
    justify-content: end !important;
    display: flex;
    gap: 10px;
}
.pagination_wrapper ul li {
    height: 34px;
    width: 34px;
    text-align: center;
    background: #f1f0f2;
    border-radius: 6px;
    color: #4B465C;
    font-weight: 400;
    font-family: "Public Sans",sans-serif;
    line-height: 34px;
}
li.previous.disabled {
    width: 120px;
}
li.previous.disabled  a{
    color: #4B465C !important;
    text-decoration: none;
}
ul li.next a{
    color: #4B465C !important;
    text-decoration: none; 
}
ul li.next {
    width: 63px;
}
.pagination_wrapper {
    padding: 0px 20px;
}
li.selected {
    background: #7367F0 !important;
    color: white !important;
}
.pagination_wrapper li:first-child{
    width: 97px;
}
li.previous a {
    text-decoration: unset;
    color: #4B465C !important;
}

 @media screen and (min-width:1536px) {
    /* .pagination_wrapper {
        padding: 14px 40px;
    }
    .pagination_wrapper ul li {
    height: 43px;
    width: 43px;
   
    }
    .pagination_wrapper li:first-child {
        width: 122px;
    }
    .pagination_wrapper ul li.next {
        width: 82px ;
    } */
 }
 @media screen and (max-width:767px){
    .pagination_wrapper ul{
        flex-wrap: wrap;
    }
    .pagination_wrapper li:last-child{
    width: 61px;
    }
    .pagination_wrapper li:first-child {
    width: 97px;
    }
    .pagination_wrapper ul li {
    height: 30px;
    width: 30px;   
    font-size: 14px;
    }
 }