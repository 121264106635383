.login_container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.shape_img_wrapper {
    position: absolute;
    left: 21rem;
    top: auto;
    bottom: 1rem;
    z-index: -1;
}
.rectangle_img_wrapper {
    position: absolute;
    left: -2rem;
    right: auto;
    top: 1rem;
    z-index: -1;
    bottom: auto;
}
.login_wrapper {
    background: white;
    padding: 30px;
    box-shadow: 2px 12px 12px #e5e4e6;
    border-radius: 6px;
    width: 450px;
    margin: 5rem 0px;
}
.signin_btn {
    padding: 10px 20px;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
}

.form-group label{
    color: #4B465C;
    font-size: 13px;
    font-weight: 400;
}
.form-group {
    margin-top: 18px;
}
.span_text{
    color: #7367F0;
    font-weight: 400;
    font-family: "Public Sans" ,sans-serif;
}
.span_text a{
    text-decoration: none !important;
    color: #7367F0 !important;
}
.password_outer{
    justify-content: space-between;
}
.password_outer h6{
    font-size: 13px;
}
.or_text{
    margin:24px 0px;
    display:flex;
    color:  #a5a2ad;
    font-size: 13px;
  }
  .or_text:before, .or_text:after {
    color:white;
    content:'';
    flex:1;
    border-bottom:1px solid #DBDADE;;
    margin: auto 20px;
  }

  /* otp  */

  .otp-inputs {
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
  }
  
  .otp-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .forgotpassword_container h6{
    text-align: right;
    padding-top: 12px;
    font-size: 14px;

  }
  .plan_ai_detector_heading{
    font-weight: 600 !important;
  }
  .cmn_semibold_font{
    font-weight: 600 !important;
  }
@media screen and (min-width:1500px){
    .login_container{
        height: 100vh;
    }
}
@media screen and (min-width:1536px){
/* .form-group label {
font-size: 18px;
}
.forgotpassword_container h6{
font-size: 18px;
}
.signin_btn {
    font-size: 20px;
} */
}
 @media screen and (max-width:1280px) {
    .signin_btn{
        padding: 7px 20px;
    }
 }
  @media screen and (max-width:767px){
    .login_wrapper{
        width: 100%;
    }
    .shape_img_wrapper{
        left: auto;
        right: 20px;
    }
    .signin_btn {
        padding: 6px 20px;
        font-size: 14px;
    }
  }